import { faArrowsRotate, faMagnifyingGlassChart, faClock, faClosedCaptioning, faCompress, faEllipsisV, faExpand, faFileDownload, faFilePdf, faFileUpload, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment/moment';
import React, { useEffect, useState } from 'react';
import { Alert, Dropdown, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ConfirmModal } from "../general/ConfirmModal";
import { EmptyPanel } from '../general/EmptyPanel';
import { ModalImportMarkersForVideo } from '../modals/ModalImportMarkersForVideo';
import { ModalMarkersFromSubtitles } from '../modals/ModalMarkersFromSubtitles';
import { getLoggedUser } from '../services/AuthorizarionService';
import { listMarkersByVideo, offsetMarkers } from '../services/VitagMarkersService';
import { usersInVideo } from '../services/VitagVideosService';
import { downloadMarkersAsCSV } from '../utils/CSVUtils';
import { GOOGLE_ICONS } from '../utils/GoogleIcons';
import { getRandomColor } from '../utils/NabUtils';
import { Typologies } from '../utils/TypologiesEnum';
import { PREFERENCES, getPreferenceAsBool, getPreferenceAsNumber,  savePreference } from '../utils/preferences/user-preferences';
import { useCustomToast } from '../utils/toasts/useCustomToast';
import { Marker } from './sidebar/Marker';
import { ProfileSelector } from './sidebar/ProfileSelector';
import { TagSelector } from './sidebar/TagSelector';
import { generateReport } from './utils/MarkerReport';
import { config } from '../config';

function MarkersBar( {
    videoHashId, vtgVideo, videoDuration, shareType, videoProvider, videoUserId, favoriteTags,
    moveToListener, onlyShowListener, startEditListener, closeSidebarListener, startDeleteListener,
    markers, setMarkers, currentMarker, setMarkerSummary, recentMarkers, setRecentMarkers,
    refresh, repaintMarkers, showSideBar, fromLive = false, itemRefs, setDisableKeys 
} ) {

    const [availableMarkers, setAvailableMarkers] = useState([]);
    const [loggedUser ] = useState(getLoggedUser());
    const [availableTags, setAvailableTags] = useState([]);
    const [filterTags, setFilterTags] = useState([]);
    const [availableUsers, setAvailableUsers] = useState([]);
    const [filterUsers, setFilterUsers] = useState([]);
    const [showModalForMarkersImport, setShowModalForMarkersImport] = useState(false);
    const [showModalMarkersFromSubtitles, setShowModalMarkersFromSubtitles] = useState(false);
    const [collapseView, setCollapseView] = useState(
        getPreferenceAsBool(PREFERENCES.MARKERS_COLLAPSED)
    );
    const [showMoveMarkersModal, setShowMoveMarkersModal] = useState(false);
    const [markersToMove, setMarkersToMove] = useState([]);
    const [offset, setOffset] = useState();
    const [offsetText, setOffsetText] = useState('0:00:00');
    const [localRefresh, setLocalRefresh] = useState();
    const { t } = useTranslation();
    const { addToast } = useCustomToast();
    const [refreshMarkers, setRefreshMarkers] = useState(false);

    useEffect(() => {
        Promise.all([usersInVideo(videoHashId), listMarkersByVideo(videoHashId)])
            .then(resp => {
                const usersMap = new Map();
                const users = resp[0].data;
                users.forEach(u => {
                    usersMap.set(u.userId, u);
                    u.selected = filterUsers.some(id => id == u.userId);
                    u.color = getRandomColor();
                    if(config.IS_BETA) console.log(`User is color ${u.color}`);
                });
                setAvailableUsers(users);
                const leMarkers = resp[1].data;
                const tagsMap = new Map();
                leMarkers.forEach(m => {
                    // Sorting tags
                    m.markerTags = (m.markerTags || []).sort(
                        (t1, t2) => t1.videoMarkerTagId - t2.videoMarkerTagId
                    );
                    // Getting available tags
                    m.markerTags.forEach(tag => tagsMap.set(tag.vtgTag.tagId, tag.vtgTag));
                    m.user = usersMap.get(m.userId);
                });
                // Keep tag filters
                const tagsToFilter = [...tagsMap.values()];
                tagsToFilter.forEach(tag => tag.selected = filterTags.some(id => id == tag.tagId));
                setAvailableTags(tagsToFilter);

                // Applying filters
                setAvailableMarkers(leMarkers);
                applyFilters(leMarkers);

                leMarkers.forEach(m => {      
                    if (m.thumbnail.includes('ytimg') && m.thumbnail.includes('?temp')) {
                        if(config.IS_BETA) console.log('Marker with Temp Thumbnail; ', m.videoMarkerId);
                        setRefreshMarkers(true);
                    } 
                });
            })
            .catch(err => {
                if(config.IS_BETA) console.error('Error getting users and markers in video', err)
            });
    }, [ refresh, localRefresh ]);

    useEffect(() => {
        markers.sort((a, b) => {
            const aTime = a.startMilliSecond > 0 ? a.startMilliSecond : a.startSecond * 1000;
            const bTime = b.startMilliSecond > 0 ? b.startMilliSecond : b.startSecond * 1000;
            return aTime - bTime;
          });
        drawMarkers();
    }, [markers, repaintMarkers, videoDuration]);

    useEffect(() => {
        let interval;
        if (refreshMarkers) {
            interval = setInterval(() => {
                handleRefreshMarkers();
                setRefreshMarkers(false);
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [refreshMarkers]);

    const drawMarkers = (leMarkers) => {
        if(! leMarkers) {
            leMarkers = markers;
        }
        let res = document.getElementsByClassName('vjs-load-progress');
        if (videoProvider?.internalId === Typologies.VT_YOUTUBE) {
            res = document.getElementById('ytMarkersBar');
        }
        if (videoProvider?.internalId === Typologies.VT_VIMEO) {
            res = document.getElementById('vimeo-progress-bar');
        }
        if (videoProvider?.internalId === Typologies.VT_TWITCH) {
            res = document.getElementById('twitch-player-markers-line');
        }
        if (videoProvider?.internalId === Typologies.VT_LIVESESSION) {
            res = document.getElementById('live-progress-bar');
        }
        if(config.IS_BETA) console.log('Trying to draw markers ;)', res);
        if (res instanceof HTMLCollection && res?.length === 0) {
            if(config.IS_BETA) console.error('Impossible to draw markers, invalid progress bar');
            return;
        }
        let leBar = null;
        if(res instanceof HTMLCollection && res?.length > 0) {
            leBar = res[0];
        } else if (res instanceof HTMLElement) {
            leBar = res;
        }
        const htmlMarkers = document.getElementsByClassName('nab-marker-fake');
        if (htmlMarkers) {
            Object.values(htmlMarkers).forEach(m => leBar?.removeChild(m));
        }
        leMarkers.forEach(marker => {
            if (marker.markerTags.length == 0) {
                if(config.IS_BETA) console.log('No tags for marker');
                return;
            }

            const marginPercent = 100 * ((marker.startMilliSecond > 0 ? marker.startMilliSecond : marker.startSecond) / (videoDuration * (marker.startMilliSecond > 0 ? 1000 : 1)));
            if(config.IS_BETA) console.log('Marker out of progress bar: ' + marginPercent + ', Duration: ' + videoDuration);
            if(marginPercent > 100) {
                if(config.IS_BETA) console.log('Marker out of progress bar');
                return;
            }
            const mt = marker.markerTags[0];
            const icon = document.createElement('span');
            const iconPresent = GOOGLE_ICONS.find(ic => ic === mt.vtgTag.icon);
            icon.className = 'nab-marker-icon text-white ' + (iconPresent ? 'material-icons-round' : '');
            icon.innerHTML = mt.vtgTag.icon;
            // icon.style.transform = 'rotateZ(45deg)';
            icon.style.cursor = 'pointer';
            icon.onclick = (event) => {
                moveToListener(marker);
                if (event.shiftKey) {
                    if (shareType === 'owner' || loggedUser.userId === marker.userId) {
                        startEditMarker(marker);
                    } else {
                        onlyShowListener(marker);
                    }
                }
            };
            const t = document.createElement('div');
            t.className = 'd-flex justify-content-center align-items-center nab-marker-fake';
            t.style.backgroundColor = mt.vtgTag.color;
            t.style.borderColor = mt.vtgTag.color;
            t.style.left = '-14px';
            // t.style.padding = '3px';
            t.style.top = '-40px';
            if(config.IS_BETA) console.log(`Marker at ${marker.startSecond} of ${videoDuration}, percent... ${marginPercent}`);
            t.style.marginLeft = marginPercent + '%';
            t.appendChild(icon);
            t.onmouseenter = _ => setMarkerSummary({...marker});
            leBar?.appendChild(t);
        });
    }

    const triggerMarkersCollapseView = () => {
        const trigger = ! collapseView;
        setCollapseView(trigger);
        savePreference(PREFERENCES.MARKERS_COLLAPSED, trigger);
    }

    const startEditMarker = (marker) => {
        setMarkerSummary(undefined);
        startEditListener && startEditListener(marker);
    }

    const openShowMarker = (marker) => {
        setMarkerSummary(undefined);
        onlyShowListener && onlyShowListener(marker);
    }

    const handleMoveMarkersModal = (value) => {
        setShowMoveMarkersModal(value);
        setDisableKeys(value);
    }

    const handleModalImportMarkersForVideo = (value) => {
        setShowModalForMarkersImport(value);
        setDisableKeys(value);
    }

    const handleModalMarkersFromSubtitles = (value) => {
        setShowModalMarkersFromSubtitles(value);
        setDisableKeys(value);
    }

    const startDeleteMarker = (marker) => {
        setMarkerSummary(undefined);
        startDeleteListener && startDeleteListener(marker);
    }

    useEffect(() => {
        setRecentMarkers([]);
        applyFilters(null, []);
    }, [filterTags, filterUsers]);

    const applyFilters = (newAvailableMarkers, newRecentMarkers) => {
        const filterMarkers = [];
        if(! newAvailableMarkers) {
            newAvailableMarkers = availableMarkers;
        }
        if(! newRecentMarkers) {
            newRecentMarkers = recentMarkers;
        }
        if(config.IS_BETA) console.log('Appliying filters using recents:', newRecentMarkers);
        newAvailableMarkers.forEach(m => {
            let filterUserMatch = true;
            if (filterUsers.length > 0) {
                filterUserMatch = filterUsers.includes(m.userId);
            }
            let filterTagMatch = true;
            if (filterTags.length > 0) {
                let includesTag = false;
                m.markerTags.forEach(mvt => {
                    if (filterTags.includes(mvt.vtgTag.tagId)) {
                        includesTag = true;
                    }
                });
                filterTagMatch = includesTag;
            }
            const isRecentMarker = newRecentMarkers.some(id => m.videoMarkerId == id);
            if (isRecentMarker || filterUserMatch && filterTagMatch) {
                filterMarkers.push(m);
            }
        });
        filterMarkers.sort((a, b) => {
            const aTime = a.startMilliSecond > 0 ? a.startMilliSecond : a.startSecond * 1000;
            const bTime = b.startMilliSecond > 0 ? b.startMilliSecond : b.startSecond * 1000;
            return aTime - bTime;
        });
        setMarkers(filterMarkers);
        // setMarkerSummary(undefined);
    }

    const openMoveMarkersModal = () => {
        const newMarkers = [...markers].filter(mtm => mtm.userId == loggedUser.userId);
        newMarkers.forEach(mtm => mtm.selected = true);
        setMarkersToMove(newMarkers);
        setShowMoveMarkersModal(true);
        setDisableKeys(true);
    }

    const handleRefreshMarkers = () => {
        setLocalRefresh(new Date());
    }

    const triggerSelectMarker = (idx, marker) => {
        const newMarkers = [...markersToMove];
        marker.selected = ! marker.selected;
        newMarkers[idx] = marker;
        setMarkersToMove(newMarkers);
    }

    const handleOffsetChange = (evt) => {
        const value = evt.target.value;
        setOffsetText(value);

        // Break down the value and check if it includes milliseconds
        const parts = value.split(':');
        let formattedValue;

        if (parts.length === 4) { //hh:mm:ss:aaa format is assumed
            const [hours, minutes, seconds, milliseconds] = parts;
            formattedValue = `${hours}:${minutes}:${seconds}.${milliseconds}`;
        } else if (parts.length === 3) { 
            const [first, second, third] = parts;
    
            if (third.length > 2) { //mm:ss:aaa format is assumed
                formattedValue = `00:${first}:${second}.${third}`;
            } else { //hh:mm:ss format is assumed
                formattedValue = `${first}:${second}:${third}`;
            }
        } else if (parts.length === 2) {
            const [first, second] = parts;
    
            if (second.length > 2) { //ss:aaa format is assumed
                formattedValue = `00:00:${first}.${second}`;
            } else { //mm:ss format is assumed
                formattedValue = `00:${first}:${second}`;
            }
        } else if (parts.length === 1){
            const first = parts[0];
    
            if (first.length > 2) {  //aaa format is assumed
                formattedValue = `00:00:00.${first}`;
            } else { //ss format is assumed
                formattedValue = `00:00:${first}.000`;
            }
        }

        const result = moment.duration(formattedValue);
        if(config.IS_BETA) console.log(result);
        if(result.isValid()) {
            const newOffset = {
            h: result.hours(),
            m: result.minutes(),
            s: result.seconds(),
            ms: result.milliseconds(),
            total: result.asMilliseconds(),
            resume: result.humanize()
        };
            setOffset(newOffset);
        } else {
            setOffset(undefined);
        }
    }

    const moveMarkers = () => {
        if(! offset) {
            return;
        }
        const toMove = markersToMove
            .filter(mtm => mtm.selected)
            .map(mtm => mtm.videoMarkerId);
        offsetMarkers(vtgVideo.videoId, offset.total, toMove)
            .then(_ => {
                setShowMoveMarkersModal(false);
                setLocalRefresh(new Date());
                addToast({
                    header: t('toast_movemarkers_success_title'),
                    body: t('toast_movemarkers_success_text'),
                    variant: "success"
                });
            })
            .catch(_ => {
                addToast({
                    header: t('toast_movemarkers_error_title'),
                    body: t('toast_movemarkers_error_title'),
                    variant: "error"
                });    
            })
    }

    const startMarkersDownload = () => {
        downloadMarkersAsCSV(videoHashId + '-markers.csv', markers);
    }

    const startMarkersImport = () => {
        setShowModalForMarkersImport(true);
        setDisableKeys(true);
    }

    const startMarkersFromSubtitles = () => {
        setShowModalMarkersFromSubtitles(true);
        setDisableKeys(true);
    }

    const getAnalysis = () => {}

    return (
        <>
        <div className="sidebar-panel border">
            <div className="sidebar-header">
                <div className="d-flex align-items-center p-2 mb-2 border-bottom">
                    <button onClick={closeSidebarListener} title={t('player_sidebar_closemarkersbar_titleattr')} className="btn btn-sm">
                        <FontAwesomeIcon icon={faTimes} size='xl'/>
                    </button>
                    <h5 className="mx-2 my-0">
                        {t('player_sidebar_title')}
                    </h5>
                    { ! fromLive &&
                    <div className="ml-auto d-flex">
                        <button onClick={handleRefreshMarkers} title={t('player_sidebar_option_refreshmarkers')} className="btn btn-sm">
                            <FontAwesomeIcon icon={faArrowsRotate} size='1x'/>
                        </button>
                        <Dropdown className='ml-auto'>
                            <Dropdown.Toggle title={t('general_options')} variant='transparent' className='without-caret' id="dropdown-basic">
                                <FontAwesomeIcon icon={faEllipsisV}/>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                { collapseView && 
                                <Dropdown.Item onClick={triggerMarkersCollapseView}>
                                    <FontAwesomeIcon icon={faExpand} className='mr-2'/>
                                    {t('player_sidebar_option_expandmarkers')}
                                </Dropdown.Item>}
                                { ! collapseView && 
                                <Dropdown.Item onClick={triggerMarkersCollapseView}>
                                    <FontAwesomeIcon icon={faCompress} className='mr-2'/>
                                    {t('player_sidebar_option_collapsemarkers')}
                                </Dropdown.Item>}
                                {/*<Dropdown.Item onClick={handleRefreshMarkers} >
                                    <FontAwesomeIcon icon={faArrowsRotate} className='mr-2'/>
                                    {t('player_sidebar_option_refreshmarkers')}
                                </Dropdown.Item>*/}
                                <Dropdown.Item onClick={openMoveMarkersModal}>
                                    <FontAwesomeIcon icon={faClock} className='mr-2'/>
                                    {t('player_sidebar_option_offsetmarkers')}
                                </Dropdown.Item>
                                <Dropdown.Item onClick={startMarkersDownload}>
                                    <FontAwesomeIcon icon={faFileDownload} className='mr-2'/>
                                    {t('player_sidebar_option_downloadmarkers')}
                                </Dropdown.Item>
                                <Dropdown.Item onClick={startMarkersImport}>
                                    <FontAwesomeIcon icon={faFileUpload} className='mr-2'/>
                                    {t('player_sidebar_option_importmarkers')}
                                </Dropdown.Item>
                                <Dropdown.Item onClick={startMarkersFromSubtitles} >
                                    <FontAwesomeIcon icon={faClosedCaptioning} className='mr-2'/>
                                    {t('player_sidebar_option_importfromcc')}
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => generateReport(markers, t)} >
                                    <FontAwesomeIcon icon={faFilePdf} className='mr-2'/>
                                    {t('player_sidebar_option_markersreport')}
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => getAnalysis} disabled={true}>
                                    <FontAwesomeIcon icon={faMagnifyingGlassChart} className='mr-2'/>
                                    {t('player_sidebar_option_analysispro')}
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown> 
                    </div>
                    }
                </div>
                { availableMarkers?.length > 0 && <>
                <TagSelector
                    availableTags={availableTags}
                    setAvailableTags={setAvailableTags}
                    filterTags={filterTags}
                    setFilterTags={setFilterTags}/>
                <ProfileSelector
                    ownerUserId={videoUserId}
                    availableUsers={availableUsers}
                    setAvailableUsers={setAvailableUsers}
                    filterUsers={filterUsers}
                    setFilterUsers={setFilterUsers}/>
                </> }
                { markers?.length === 0 &&
                    <EmptyPanel message={t('player_sidebar_message_nomarks')} extraClass="bg-transparent small"/> }
            </div>

            <div className='px-0 pt-2 sidebar-content' style={{overflowY:'scroll'}}>
            { markers?.map(marker =>
                <div key={marker?.videoMarkerId} id={'marker-on-sidebar-'+marker?.videoMarkerId}
                    className="py-2 px-3">
                    <div className={'extra-rounded ' + (marker?.videoMarkerId == currentMarker?.videoMarkerId ? 'vitag-shadow with-blink' : '')}>
                        <Marker
                            marker={marker}
                            repaintImage={showSideBar}
                            showActionsOnMarkers={! fromLive}
                            shareType={shareType}
                            videoProvider={videoProvider}
                            // userNickname={marker?.user?.nickname}
                            userNickname={marker?.user?.fullName}
                            loggedUser={loggedUser}
                            collapseView={collapseView}
                            moveToListener={moveToListener}
                            confirmDeleteMarker={startDeleteMarker}
                            startEditMarker={startEditMarker}
                            openShowMarker={openShowMarker}
                            itemRefs={itemRefs} />
                    </div>
                </div>)}
            </div>
        </div>

            <ConfirmModal
                modalTitle={t('player_sidebar_modal_movemarkers_title')}
                closeOnSuccess={false}
                acceptCallback={moveMarkers}
                showModal={showMoveMarkersModal}
                setShowModal={handleMoveMarkersModal}
                >
                <Alert variant='warning'>
                    {t('player_sidebar_modal_movemarkers_warn')}
                </Alert>
                <InputGroup>
                    <InputGroup.Prepend>
                        <InputGroup.Text>
                            {t('player_sidebar_modal_offset_label')}
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <input type='text' value={offsetText} onChange={handleOffsetChange} placeholder='player_sidebar_modal_offset_placeholder' className='form-control'/>
                </InputGroup>
                <div className="d-flex flex-column">
                    <span className="ml-auto mb-3 text-muted small">{t('player_sidebar_modal_offset_extra')}</span>
                    <p>{t('player_sidebar_modal_text')} {offset && ('~' + offset?.resume)}</p>
                </div>
                <table className="table table-sm table-hover py-3">
                    <tbody>
                        { markersToMove.map((mtm, i) =>
                        <Marker
                            key={mtm.videoMarkerId}
                            marker={mtm}
                            className='flex-fill'
                            shareType={shareType}
                            videoProvider={videoProvider}
                            userNickname={mtm?.user?.nickname}
                            moveToListener={() => triggerSelectMarker(i, mtm)}
                            loggedUser={loggedUser}
                            tableView={true}>
                        <input checked={mtm.selected} onChange={() => triggerSelectMarker(i, mtm)} type='checkbox'/>
                        </Marker>)}
                    </tbody>
                </table>
            </ConfirmModal>

            <ModalImportMarkersForVideo
                vtgVideo={vtgVideo}
                showModal={showModalForMarkersImport}
                setShowModal={handleModalImportMarkersForVideo}
                setDisableKeys={setDisableKeys}
                successImportListener={() => setLocalRefresh(new Date())}/>

            <ModalMarkersFromSubtitles
                vtgVideo={vtgVideo}
                availableTags={favoriteTags}
                forceMarkersRefresh={() => setLocalRefresh(new Date())}
                showModal={showModalMarkersFromSubtitles}
                setShowModal={handleModalMarkersFromSubtitles}/>

        </>
    );
}

export { MarkersBar };
