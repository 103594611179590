import React, { useEffect, useRef, useState, useCallback  } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Row, Modal, Col, Tabs, Tab } from 'react-bootstrap';
import { addMarkerAtVideo, updateMarkerAtVideo } from '../services/VitagMarkersService';
import { listAllTags } from '../services/VitagTagsService';
import { IconPill } from '../general/IconPill';
import { uploadImageToS3 } from '../services/CoreService';
import { convertSecondsToTime, convertMillisecondsToTime, convertTextToMilliseconds } from '../utils/NabUtils';
import { useTranslation } from 'react-i18next';
import { configToOpenLinks } from '../utils/CKEditorConfig';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import '@ckeditor/ckeditor5-build-decoupled-document/build/translations/es';
import { CommentsPanel } from '../general/CommentsPanel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faTimesCircle }  from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { getPreferenceAsNumber, PREFERENCES } from "../utils/preferences/user-preferences";
import { config } from '../config';


function ModalManageMarker({
        currentVideoId, currentMarker, currentThumbnail, currentTag, currentTimeAsSeconds, currentTimeAsMilliseconds, 
        playControl, pauseControl,forwardTimerControl, backwardTimerControl, forwardControl, backwardControl, playingState, currentTimeAsText,
        newMarkerListener, showModal, setShowModal, confirmDeleteListener, closeMarkerManager, showTimerOnly, setShowTimerOnly
    } ) {

    const [buttonPads, setButtonPads] = useState([]);
    const [currentButtonPad, setCurrentButtonPad] = useState();
    //const [currentTimeAsText, setCurrentTimeAsText] = useState('-:--');
    const [startOffsetAsText, setStartOffsetAsText] = useState('00:02:000');
    const [endOffsetAsText, setEndOffsetAsText] = useState('00:02:000');
    const [invalidStartInterval, setInvalidStartInterval] = useState(false);
    const [invalidEndInterval, setInvalidEndInterval] = useState(false);
    const [intervalDuration, setIntervalDuration] = useState(2000);
    const [currentStartOffset, setCurrentStartOffset] = useState(getPreferenceAsNumber(PREFERENCES.PLAYER_DEFAULT_OFFSET_BEFORE));
    const [currentEndOffset, setCurrentEndOffset] = useState(getPreferenceAsNumber(PREFERENCES.PLAYER_DEFAULT_OFFSET_AFTER));
    const [modalOpacity, setModalOpacity] = useState(getPreferenceAsNumber(PREFERENCES.MODAL_OPACITY));
    const [selectedTags, setSelectedTags] = useState([]);
    const [uploadingImage, setUploadingImage] = useState(false);
    const [markerThumbnail, setMarkerThumbnail] = useState();
    const [marker, setMarker] = useState();
    const [working, setWorking] = useState(false);
    const [transparent, setTransparent] = useState('FF');
    const [transparentButtonClass, setTransparentButtonClass] = useState('btn-dark');
    const [tagPriority, setTagPriority] = useState(1);
    const [lang, setLang] = useState('en');
    const { t, i18n } = useTranslation();
    const editor = useRef(null);
    const inputRefTitle = useRef(null);
    const [imageLoadError, setImageLoadError] = useState(false);

    useEffect(() => {
        modalInit();
    }, []);

    useEffect(() => {
        setLang(i18n.language.substring(0, 2));
    }, [i18n.language]);

    useEffect(() => {
        setMarkerThumbnail(currentThumbnail);
    }, [currentThumbnail])

    useEffect(() => {
        const newTimeDetailMs = convertMillisecondsToTime(currentTimeAsMilliseconds);
        const newTimeDetail = convertSecondsToTime(currentTimeAsSeconds);
    }, [currentTimeAsMilliseconds, currentTimeAsSeconds])

    const safePlayPauseControl = () => {
        playingState ? pauseControl && pauseControl() : playControl && playControl();
    }

    const safeShowOnlyTimer = () => {
        setShowTimerOnly(!showTimerOnly);
    }

    const handleInputChange  = (evt, isStart) => {
        const newValue = evt.target.value;
        const convertedTime  = convertTextToMilliseconds(newValue);

        if (isStart) {
            setStartOffsetAsText(newValue);
            if (convertedTime !== false) {
                setCurrentStartOffset(convertedTime);
                setMarker(marker => ({ ...marker, startOffset: convertedTime }));
                setInvalidStartInterval(false);
                //backwardTimerControl();
            } else {
                setInvalidStartInterval(true);    
            }
        } else {
            setEndOffsetAsText(newValue);
            if (convertedTime !== false ) {
                setCurrentEndOffset(convertedTime);
                setMarker(marker => ({ ...marker, endOffset: convertedTime }));
                setInvalidEndInterval(false);
                //forwardTimerControl();
            } else {
                setInvalidEndInterval(true);
            }
        }
    };

    const changeInterval = (target, backward) => {
        const startOt = convertTextToMilliseconds(startOffsetAsText);
        const endOt = convertTextToMilliseconds(endOffsetAsText);
        let newValueStart = startOt;
        let newValueEnd = endOt;

        if (startOt !== false && endOt !== false) {
            const adjustment = backward ? -100 : 100;           
            if (target === 'start') {
                newValueStart = Math.max(startOt + adjustment, 0);

                setStartOffsetAsText(convertMillisecondsToTime(newValueStart));
                setCurrentStartOffset(newValueStart);
                setMarker(marker => ({ ...marker, startOffset: newValueStart }));
                setInvalidStartInterval(false);
                //backwardTimerControl();
            } else {
                newValueEnd = Math.max(endOt + adjustment, 0);

                setEndOffsetAsText(convertMillisecondsToTime(newValueEnd));
                setCurrentEndOffset(newValueEnd);
                setMarker(marker => ({ ...marker, endOffset: newValueEnd }));
                setInvalidEndInterval(false);
                //forwardTimerControl();
            }
        }
    };

    useEffect(() => {
        setIntervalDuration(currentStartOffset + currentEndOffset);
    }, [currentStartOffset, currentEndOffset]);

    const triggerModalTransparent = () => {
        if (transparent === 'FF') {
            makeModalTransparent();
        } else {
            makeModalVisible();
        }
    }

    const startDeleteMarker = () => {
        confirmDeleteListener && confirmDeleteListener({...currentMarker, startSecond:currentTimeAsSeconds, startMilliSecond:currentTimeAsMilliseconds});
        closeThisModal();
    }

    const makeModalTransparent = () => {
        localStorage.setItem('alpha', '55');
        setTransparent('55');
        setTransparentButtonClass('btn-outline-light');
    }

    const makeModalVisible = () => {
        localStorage.setItem('alpha', 'FF');
        setTransparent('FF');
        setTransparentButtonClass('btn-outline-dark')
    }

    const selectButtonPad = (pad) => {
        const newButtonPad = [...buttonPads];
        setButtonPads(newButtonPad);
        setCurrentButtonPad(pad);
    }

    const selectButtonPadAndAddTag = (pad) => {
        selectButtonPad(pad);
        selectTag(pad);
    }

    const makeLeadTag = (leTag, position) => {
        if(config.IS_BETA) console.log(`Making lead tag: ${leTag.name}, ${position} with priority ${tagPriority}`);
        const newTag = {...leTag, priority: tagPriority};
        const currentSelection = [...selectedTags];
        currentSelection.splice(position, 1, newTag);
        currentSelection.sort((t1, t2) => t2.priority - t1.priority);
        if(config.IS_BETA) console.log('New array:', currentSelection);
        setSelectedTags(currentSelection);
        setTagPriority(tagPriority + 2);
    }

    const selectTag = (leTag, resetSelectedTags = false) => {
        const currentSelection = resetSelectedTags ? [] : [...selectedTags];
        leTag.priority = 0;
        let repeated = false;
        currentSelection.forEach(tag => {
            if (tag.tagId === leTag.tagId) {
                repeated = true;
            }
        });
        if (repeated) {
            if(config.IS_BETA) console.error('Repeated tag');
            return;
        }
        setSelectedTags([leTag, ...currentSelection]);
        buttonPads.forEach(pad => {
            if (leTag.name.toLowerCase() === pad.name.toLowerCase()) {
                selectButtonPad(pad);
            }
        });
    }

    const unselectTag = (leTag) => {
        const newSelection = selectedTags.filter(tag => tag.tagId !== leTag.tagId);
        setSelectedTags(newSelection);
    }

    const saveMarker = () => {
        setWorking(true);
        
        new Promise((response, reject) => {
            if (! markerThumbnail?.startsWith('data:image')) {
                response(markerThumbnail);
                return;
            }
            uploadImageToS3(markerThumbnail)
                .then(resp => response(resp.data.imageUrl))
                .catch(err => reject(err));
        }).then(thumbnailUrl => {
            const leMarker = {
                videoMarkerId: currentMarker?.videoMarkerId,
                videoId: currentVideoId,
                userId: currentMarker?.userId,
                entryDate: currentMarker?.entryDate,
                status: currentMarker?.status,
                title: marker?.title,
                description: marker?.description,
                thumbnail: thumbnailUrl,
                startSecond: currentTimeAsSeconds,
                startMilliSecond: currentTimeAsMilliseconds,
                startOffset: typeof currentStartOffset !== 'undefined' ? currentStartOffset:getPreferenceAsNumber(PREFERENCES.PLAYER_DEFAULT_OFFSET_BEFORE),
                endOffset: typeof currentEndOffset !== 'undefined' ? currentEndOffset:getPreferenceAsNumber(PREFERENCES.PLAYER_DEFAULT_OFFSET_AFTER),
                markerTags:selectedTags.map(t => ({vtgTag:t}))
            };

            return currentMarker?
                updateMarkerAtVideo(leMarker, leMarker.videoMarkerId):
                addMarkerAtVideo(leMarker);
        }).then(resp => {
            if(config.IS_BETA) console.log('Marker saved/updated successful', resp);
            newMarkerListener && newMarkerListener(resp.data);
            closeThisModal();
            setWorking(false);
        }).catch(err => {
            if(config.IS_BETA) console.error('Error saving marker at video', err);
            setWorking(false);
        });
    }

    const validateMark = () => {
        if (selectedTags.length === 0 || 
            invalidStartInterval || invalidEndInterval
        ) return true;
    }

    const handleImageSelection = (event) => {
        setUploadingImage(true);
        const imageFile = event.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            uploadImageToS3(reader.result)
                .then(resp => {
                    setUploadingImage(false);
                    const s3Url = resp.data.imageUrl;
                    const uniqueUrl = `${s3Url}?t=${new Date().getTime()}`;
                    setMarkerThumbnail(uniqueUrl);
                })
                .catch(err => {
                    setUploadingImage(false);
                    if(config.IS_BETA) console.error('Impossible save image...', err);
                });
        }
        reader.readAsDataURL(imageFile);
    }

    const checkImageLoad = useCallback((url) => {
        const img = new Image();
        img.src = url;
        img.onload = () => {
            if(config.IS_BETA) console.log('Image loaded successfully');
            setImageLoadError(false);
            setUploadingImage(false);
        };
        img.onerror = () => {
            setUploadingImage(true);
            if(config.IS_BETA) console.error('Failed to load image');
            setImageLoadError(true);
            setTimeout(() => checkImageLoad(url), 3000);
        };
    }, []);

    useEffect(() => {
        if (markerThumbnail) {
            checkImageLoad(markerThumbnail);
        }
    }, [markerThumbnail, checkImageLoad]);

    const closeThisModal = () => {
        closeMarkerManager && closeMarkerManager();
        setShowModal(false);
    }

    const initInterval = () => {

        const start = (typeof currentMarker?.startOffset !== 'undefined') ? currentMarker.startOffset:getPreferenceAsNumber(PREFERENCES.PLAYER_DEFAULT_OFFSET_BEFORE);
        const end = (typeof currentMarker?.endOffset !== 'undefined') ? currentMarker.endOffset:getPreferenceAsNumber(PREFERENCES.PLAYER_DEFAULT_OFFSET_AFTER);
        setStartOffsetAsText(convertMillisecondsToTime(start));
        setEndOffsetAsText(convertMillisecondsToTime(end));
        
        setCurrentStartOffset(start);
        setCurrentEndOffset(end);
    }

    const modalInit = () => {
        if (currentTag) {
            setCurrentButtonPad(currentTag);
            selectTag(currentTag, true);
        } else {
            setCurrentButtonPad(undefined);
            setSelectedTags([]);
        }
        initInterval();
        if (currentMarker) {
            initInterval();

            setMarker({
                title: currentMarker.title,
                description: currentMarker.description,
                startOffset: currentMarker.startOffset,
                endOffset: currentMarker.endOffset
            });
            editor.current?.setData(currentMarker.description);
            setSelectedTags((currentMarker.markerTags || [])
                .map(t => {
                    const tg = t.vtgTag;
                    tg.priority = 0;
                    return tg;
                }));
            setMarkerThumbnail(currentMarker.thumbnail);
        } else {
            setMarker({title: '', description: ''});
            setMarkerThumbnail(currentThumbnail);
            editor.current?.setData('');
        }
        const lsTransparent = localStorage.getItem('alpha');
        if (lsTransparent === 'FF') {
            makeModalVisible();
        } else {
            makeModalTransparent();
        }
        buttonPads.forEach(bp => bp.selected = false);
        setButtonPads([...buttonPads]);
        listAllTags('', 0, 50, false, true)
            .then(resp => {
                const sortedTags = (resp.data || []).sort((t1, t2) => (t2.isFav * 1000 + t2.name) - (t1.isFav * 1000 + t1.name));
                if(config.IS_BETA) console.log('Sorted tags...', sortedTags);
                setButtonPads(sortedTags);
            })
            .catch(err => {
                if(config.IS_BETA) console.error('Error getting tags', err)
            });
    }

    useEffect(() => {
        if (showModal) {
            const timer = setTimeout(() => {
                if (inputRefTitle.current) {
                    inputRefTitle.current.focus();
                }
            }, 200);
            return () => clearTimeout(timer);
        }
      }, [showModal]);

    return (<>
    <Modal show={showModal} onHide={closeThisModal} onShow={modalInit} size={(!showTimerOnly ? "xl" : "lg")} contentClassName={(transparent == 'FF' ? '' : 'semi-transparent')}>
        <Modal.Header style={{backgroundColor:'#ffffff' + transparent}}>
            <div className="d-flex justify-content-between w-100">
                <div className="d-flex gap-1">
                    <button onClick={closeThisModal}
                        title={t('modal_managemarker_btn_cancel_titleattr')}
                        className={'btn btn-sm border-0 line-0 align-self-center ' + transparentButtonClass}>
                        <span className="material-icons-round">close</span>
                    </button>
                    <span className="btn-placeholder"></span>
                    <span className="btn-placeholder"></span>
                </div>
                <Modal.Title className="my-auto">
                    { t(currentMarker ? (showTimerOnly ? 'modal_managemarker_title_timemarker' : 'modal_managemarker_title_editmarker') : 'modal_managemarker_title_addnewmarker') }
                </Modal.Title>
                <div className="d-flex gap-2">
                    <button onClick={startDeleteMarker}
                        title={t('modal_managemarker_btn_delete_titleattr')}
                        className={'btn btn-sm border-0 line-0 align-self-center ' + transparentButtonClass}>
                        <span className="material-icons-round">delete</span>
                    </button>
                    <button onClick={safeShowOnlyTimer}
                        title={t('modal_managemarker_title_timemarker')}
                        className={'btn btn-sm border-0 line-0 align-self-center ' + transparentButtonClass}>
                        <span className="material-icons-round">timer</span>
                    </button>
                    <button onClick={triggerModalTransparent}
                        title={t('modal_managemarker_btn_toggletransparency_titleattr')}
                        className={'btn btn-sm border-0 line-0 align-self-center ' + transparentButtonClass}>
                        <span className="material-icons-round">contrast</span>
                    </button>
                    <button onClick={saveMarker} disabled={working || validateMark()}
                        title={t('modal_managemarker_btn_save_titleattr')}
                        className={'btn btn-sm border-0 line-0 align-self-center ' + transparentButtonClass}>
                        <span className="material-icons-round">save</span>
                    </button>
                </div>
            </div>
        </Modal.Header>
        <Modal.Body className='px-5' style={{backgroundColor:'#ffffff' + transparent}}>
            <Row>
                {!showTimerOnly && 
                    <Col xs={12} md={5}>
                        <div className="w-100 position-relative mb-3">
                            <label className="d-block">
                                {t('modal_managemarker_label_thumbnail')}
                            </label>
                            {!imageLoadError && 
                            <img src={markerThumbnail || '#'} 
                                alt="Thumbnail"  
                                className="d-block border w-100 mx-auto" 
                                style={{maxHeight:'180px',objectFit:'contain',backgroundColor:'#ececec'}}
                                key={markerThumbnail}>
                            </img>}
                            {imageLoadError && 
                            <img alt=""  
                                className="d-block border w-100 mx-auto" 
                                style={{maxHeight:'180px',objectFit:'contain',backgroundColor:'#ececec'}}>
                            
                            </img>}
                            { uploadingImage && <div className="d-flex" style={{bottom:'8px',left:'8px'}}>
                                <div className="spinner-border spinner-border-sm my-auto"></div>
                                <span className="text-muted my-auto ml-2">
                                    {t('general_loading_text')}
                                </span>
                            </div> }
                            <label htmlFor='hidden-thumbnail-upload'
                                title={t('modal_managemarker_btn_uploadcustomthumbnail_titleattr')}
                                className='fake-btn position-absolute material-icons-round'
                                style={{bottom:'0',right:'16%',cursor:'pointer'}}>upload</label>
                            <input id='hidden-thumbnail-upload' onChange={handleImageSelection} type="file" accept="image/*" className='d-none'/>
                        </div>

                        <div className="form-group">
                            <label htmlFor="inputMarkerTitle">
                                {t('modal_managemarker_label_title')}
                            </label>
                            <input ref={inputRefTitle} value={marker?.title} onChange={evt => setMarker({...marker, title:evt.target.value})}
                                type="text" className="form-control" id="inputMarkerTitle" style={{fontSize:'1.20rem'}}
                                placeholder={t('modal_managemarker_placeholder_title')}/>
                        </div>
                        <div className="form-group">
                            <div className="d-flex justify-content-between mt-2 gap-0">
                                <label htmlFor="inputMarkerTime">
                                    {t('modal_managemarker_label_range')}
                                </label>
                                <label htmlFor="inputStartTime" className='mt-auto' style={{fontSize:'0.9rem'}}>
                                    {t('modal_managemarker_label_duration', {time:convertMillisecondsToTime(intervalDuration)})}
                                </label>    
                            </div>
                            
                            <div className="d-flex justify-content-between mt-2 gap-0">
                                <div className="input-group-interval">
                                    <div className="input-group-prepend-interval gap-0 mx-0">
                                        <button className='mx-0' onClick={() => backwardControl(5)}>-5</button>
                                        <button className='mx-0' onClick={() => backwardControl(1)}>-1</button>
                                        <button className='mx-0' onClick={() => backwardControl(0.1)}>-0.1</button>
                                    </div>
                                    <input type="text" className="form-control" style={{textAlign:'center'}}
                                        value={currentTimeAsText} readOnly={true} disabled="disabled"
                                        id="inputMarkerTime" placeholder='mm:ss:aa' />
                                    <div className="input-group-append-interval">
                                        <button className='mx-0' onClick={() => forwardControl(0.1)}>+0.1</button>
                                        <button className='mx-0' onClick={() => forwardControl(1)}>+1</button>
                                        <button className='mx-0' onClick={() => forwardControl(5)}>+5</button>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between mt-2 gap-0">
                                <div className="d-flex flex-column align-items-center">
                                    <div className="input-group pr-1">
                                        <div className="input-group-prepend gap-0 mx-0">
                                            <button className='input-group-text justify-content-center' onClick={() => changeInterval('start', true)} style={{width: 'calc(1.5em + .75rem + 2px)', fontSize:'0.95rem', padding:'.375rem .4rem'}}>-</button>
                                        </div>
                                        <input type="text" className={classNames('form-control', { 'invalid': invalidStartInterval })} style={{fontSize:'0.95rem', textAlign:'center'}}
                                            onChange={(e) => handleInputChange(e, true)}
                                            value={startOffsetAsText} title={t('modal_managemarker_labeltitle_startinterval')}
                                            id="inputStartTime" placeholder='mm:ss:aa' />
                                        <div className="input-group-append">
                                            <button className='input-group-text justify-content-center' onClick={() => changeInterval('start', false)} style={{width: 'calc(1.5em + .75rem + 2px)', fontSize:'0.95rem', padding:'.375rem .4rem'}}>+</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex flex-column align-items-center">
                                    <button onClick={safePlayPauseControl} style={{height: 'calc(1.5em + .75rem + 2px)'}}
                                        title={t(playingState ? 'modal_managemarker_btn_pause_titleattr' : 'modal_managemarker_btn_play_titleattr')}
                                        className={'btn btn-sm py-0 cursor-pointer ' + transparentButtonClass}>
                                        <span className="mt-1 material-icons-round">{ playingState ? 'pause' : 'play_arrow' }</span> 
                                    </button>    
                                </div>
                                <div className="d-flex flex-column align-items-center">
                                    <div className="input-group pl-1">
                                        <div className="input-group-prepend gap-0 mx-0">
                                            <button className='input-group-text justify-content-center' onClick={() => changeInterval('end', true)} style={{width: 'calc(1.5em + .75rem + 2px)', fontSize:'0.95rem', padding:'.375rem .4rem'}}>-</button>
                                        </div>
                                        <input type="text" className={classNames('form-control', { 'invalid': invalidEndInterval })} style={{fontSize:'0.95rem', textAlign:'center'}}
                                            onChange={(e) => handleInputChange(e, false)}
                                            value={endOffsetAsText} title={t('modal_managemarker_labeltitle_endinterval')}
                                            id="inputEndTime" placeholder='mm:ss:aa' />
                                        <div className="input-group-append">
                                            <button className='input-group-text justify-content-center' onClick={() => changeInterval('end', false)} style={{width: 'calc(1.5em + .75rem + 2px)', fontSize:'0.95rem', padding:'.375rem .4rem'}}>+</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>}
                {!showTimerOnly && 
                    <Col xs={12} md={7}>
                        <div className="d-flex x-50">
                            <label className="my-auto">{t('modal_managemarker_label_selectkeypad')}</label>
                        </div>
                        <div className="d-flex x-50 justify-content-end">
                            <button className="d-none btn btn-primary bg-vitag disabled">{t('modal_managemarker_label_newtag')}</button>
                        </div>
                        <div className="d-flex mt-2" style={{overflowY:'scroll'}}>
                        { buttonPads.map(pad =>
                            <IconPill key={pad.tagId} className='mx-1'
                                onPillClick={() => selectButtonPadAndAddTag(pad)}
                                defaultSelected={pad.tagId == currentButtonPad?.tagId}
                                iconName={pad.name} iconText={pad.icon} iconColor={pad.color}
                                pillText={pad.name} animated={true}/>
                        )}
                        </div>
                        { currentButtonPad &&
                        <div className='d-flex py-2'>
                            <div className="w-100 border py-2 px-1 d-flex" style={{overflowY:'scroll'}}>
                                { currentButtonPad.subTags?.map(tag => 
                                <IconPill key={tag.tagId} className="mx-1"
                                    onPillClick={() => selectTag(tag)}
                                    iconText={tag.icon} iconColor={tag.color} pillText={tag.name}/>)}
                                { ! currentButtonPad.subTags?.length &&
                                    <p className='text-muted my-0 mx-auto'>{t('modal_managemarker_label_selectkeypad')}</p>}
                            </div>
                        </div> }
                        { selectedTags &&
                        <div className="d-flex flex-column pt-3">
                            <label>{t('modal_managemarker_label_selectedtagsformarker')}</label>
                            <div className="w-100 border py-2 px-1 d-flex" style={{overflowY:'scroll'}}>
                                { selectedTags.map((tag, i) =>
                                <IconPill key={tag.tagId} className='mx-1'
                                    onCloseClick={()=>unselectTag(tag)} onPillClick={()=>makeLeadTag(tag, i)}
                                    iconText={tag.icon} iconColor={tag.color} pillText={tag.name} closable={true}/>)}
                                { selectedTags.length == 0 &&
                                    <p className="text-muted mx-auto my-0">
                                        {t('modal_managemarker_message_withoutselectedtags')}
                                    </p> }
                            </div>
                        </div> }
                        <div className="w-100 py-3 mt-2">
                            <Tabs defaultActiveKey="description">
                                <Tab eventKey="description" title={t('modal_managemarker_title_description')}>
                                    <div className="w-100 border-left border-right border-bottom">
                                        <CKEditor
                                            config={{...configToOpenLinks, language: lang}}
                                            editor={DecoupledEditor}
                                            data={marker?.description}
                                            onReady={edt => {
                                                edt.focus();
                                                edt.ui.view.editable.element.style.minHeight = '200px';
                                                edt.ui.view.toolbar.element.style.borderTop = 'none';
                                                edt.ui.view.toolbar.element.style.borderLeft = 'none';
                                                edt.ui.view.toolbar.element.style.borderRight = 'none';
                                                edt.ui.getEditableElement().parentElement.insertBefore(
                                                    edt.ui.view.toolbar.element,
                                                    edt.ui.getEditableElement()
                                                );
                                                editor.current = edt;
                                                editor.current.setData(marker.description);
                                            }}
                                            onChange={(_, edt) => {
                                                edt.ui.view.editable.element.style.minHeight = '200px';
                                                const data = edt.getData();
                                                setMarker({...marker, description:data});
                                            }}/>
                                    </div>
                                </Tab>
                                <Tab eventKey="comments" title={t('modal_managemarker_title_comments')} disabled={! currentMarker}>
                                    <div className="border-bottom border-left border-right px-3">
                                        <CommentsPanel videoMarkerId={currentMarker?.videoMarkerId}/>
                                    </div>
                                </Tab>
                                {/*<Tab eventKey="attachs" title={t('modal_managemarker_title_attach')}>
                                    <div className="border-bottom border-left border-right px-3">
                                        {<CommentsPanel videoMarkerId={currentMarker?.videoMarkerId}/>}
                                    </div>
                                </Tab>*/}
                            </Tabs>
                        </div>
                    </Col>
                }
                {showTimerOnly && 
                    <Col xs={12} md={9}>
                        <div className="d-flex justify-content-between mt-2 gap-0">
                                <div className="input-group-interval">
                                    <div className="input-group-prepend-interval gap-0 mx-0">
                                        <button className='mx-0' onClick={() => backwardControl(5)}>-5</button>
                                        <button className='mx-0' onClick={() => backwardControl(1)}>-1</button>
                                        <button className='mx-0' onClick={() => backwardControl(0.1)}>-0.1</button>
                                    </div>
                                    <input type="text" className="form-control" style={{textAlign:'center'}}
                                        value={currentTimeAsText} readOnly={true} disabled="disabled"
                                        id="inputMarkerTime" placeholder='mm:ss:aa' />
                                    <div className="input-group-append-interval">
                                        <button className='mx-0' onClick={() => forwardControl(0.1)}>+0.1</button>
                                        <button className='mx-0' onClick={() => forwardControl(1)}>+1</button>
                                        <button className='mx-0' onClick={() => forwardControl(5)}>+5</button>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between mt-2 gap-0">
                                <div className="d-flex flex-column align-items-center">
                                    <div className="input-group pr-1">
                                        <div className="input-group-prepend gap-0 mx-0">
                                            <button className='input-group-text justify-content-center' onClick={() => changeInterval('start', true)} style={{width: 'calc(1.5em + .75rem + 2px)', fontSize:'0.95rem', padding:'.375rem .4rem'}}>-</button>
                                        </div>
                                        <input  type="text" className={classNames('form-control', { 'invalid': invalidStartInterval })} style={{fontSize:'0.95rem', textAlign:'center'}}
                                                onChange={(e) => handleInputChange(e, true)}
                                                value={startOffsetAsText} title={t('modal_managemarker_labeltitle_startinterval')}
                                                id="inputStartTime" placeholder='mm:ss:aa' />
                                        <div className="input-group-append">
                                            <button className='input-group-text justify-content-center' onClick={() => changeInterval('start', false)} style={{width: 'calc(1.5em + .75rem + 2px)', fontSize:'0.95rem', padding:'.375rem .4rem'}}>+</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex flex-column align-items-center">
                                    <button onClick={safePlayPauseControl} style={{height: 'calc(1.5em + .75rem + 2px)'}}
                                        title={t(playingState ? 'modal_managemarker_btn_pause_titleattr' : 'modal_managemarker_btn_play_titleattr')}
                                        className={'btn btn-sm py-0 cursor-pointer ' + transparentButtonClass}>
                                        <span className="mt-1 material-icons-round">{ playingState ? 'pause' : 'play_arrow' }</span> 
                                    </button>    
                                </div>
                                <div className="d-flex flex-column align-items-center">
                                    <div className="input-group pl-1">
                                        <div className="input-group-prepend gap-0 mx-0">
                                            <button className='input-group-text justify-content-center' onClick={() => changeInterval('end', true)} style={{width: 'calc(1.5em + .75rem + 2px)', fontSize:'0.95rem', padding:'.375rem .4rem'}}>-</button>
                                        </div>
                                        <input type="text" className={classNames('form-control', { 'invalid': invalidEndInterval })} style={{fontSize:'0.95rem', textAlign:'center'}}
                                            onChange={(e) => handleInputChange(e, false)}
                                            value={endOffsetAsText} title={t('modal_managemarker_labeltitle_endinterval')}
                                            id="inputEndTime" placeholder='mm:ss:aa' />
                                        <div className="input-group-append">
                                            <button className='input-group-text justify-content-center' onClick={() => changeInterval('end', false)} style={{width: 'calc(1.5em + .75rem + 2px)', fontSize:'0.95rem', padding:'.375rem .4rem'}}>+</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </Col>}
                {showTimerOnly && 
                    <Col xs={12} md={3}>
                        <label htmlFor="inputStartTime" className='mt-auto text-right' style={{fontSize:'0.9rem'}}>
                            {t('modal_managemarker_labeltitle_clipduration', {time:convertMillisecondsToTime(intervalDuration)})}
                        </label> 
                    </Col>}
                
            </Row>
        </Modal.Body>
    </Modal>

    <style>
        {`
          .modal-backdrop.show {
            opacity: ${modalOpacity} !important;
          }
        `}
    </style>
    </>)

}

export { ModalManageMarker };