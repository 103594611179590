import React, { useRef, useState } from 'react';
import { Row, Col, Modal } from 'react-bootstrap';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { listAllTags } from '../services/VitagTagsService';
import { IconPill } from '../general/IconPill';
import { listAllLists } from '../services/VitagListsService';
import { config } from '../config';

function ModalAvancedSearch({
    showModal, setShowModal, currentOnlyDeleted,
    avancedSearchListener, currentSearchTags = [], currentLists
}) {

    const [availableLists, setAvailableLists] = useState([]);
    const [selectedLists, setSelectedLists] = useState([]);
    const [availableTags, setAvailableTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [searchTags, setSearchTags] = useState('');
    const [titleSearch, setTitleSearch] = useState('');
    const [showOnlyDeleted, setShowOnlyDeleted] = useState(false);
    const { t } = useTranslation();
    const lastTimeoutId = useRef(-1);

    const initModal = () => {
        setShowOnlyDeleted(currentOnlyDeleted);
        setSelectedTags(currentSearchTags);
        setSelectedLists(currentLists);
        setSearchTags('');
        getTags('');
        getLists();
    }

    const performSearch = () => {
        if(config.IS_BETA) console.log('Only deleted? ' + showOnlyDeleted);
        avancedSearchListener && avancedSearchListener(showOnlyDeleted, titleSearch, selectedTags, selectedLists);
        closeThisModal();
    }

    const getTags = (search) => {
        listAllTags(search, 0, -1, true).then(resp => setAvailableTags(resp.data));
    }

    const getLists = () => {
        listAllLists().then(resp =>
            setAvailableLists(
                resp.data.map(list => ({value:list.listId, label:list.listName}))
            )
        ).catch(err => {
            if(config.IS_BETA) console.error('Error getting lists')
        });
    }

    const selectTag = (tag) => {
        const founded = selectedTags.find(st => st.tagId == tag.tagId);
        if (founded) {
            if(config.IS_BETA) console.log('Repeated tag');
            return;
        }
        setSelectedTags([tag, ...selectedTags]);
    }

    const removeTag = (tag) => {
        setSelectedTags([...selectedTags.filter(t => t.tagId !== tag.tagId)]);
    }

    const removeAll = () => setSelectedTags([]);

    const handleSearchTags = (evt) => {
        const newSearch = evt.target.value;
        setSearchTags(newSearch);
        if (lastTimeoutId.current !== -1) {
            clearTimeout(lastTimeoutId.current);
        }
        lastTimeoutId.current = setTimeout(getTags, 600, newSearch);
    }

    const handleTitleSearch = evt => setTitleSearch(evt.target.value);

    const closeThisModal = () => setShowModal(false);

    return(
    <Modal show={showModal} onShow={initModal} onHide={closeThisModal} size='lg'>
        <Modal.Header>
            <div className="d-flex justify-content-between w-100">
                <button onClick={closeThisModal}
                    title={t('modal_videosearch_cancel')}
                    className="btn btn-sm text-secondary">
                    <span className="material-icons-round">close</span>
                </button>
                <Modal.Title>
                    {t('modal_videosearch_title_advancedsearch')}
                </Modal.Title>
                <button onClick={performSearch}
                    title={t('modal_videosearch_search')}
                    className="btn btn-sm text-vitag">
                    <span className="material-icons-round">search</span>
                </button>
            </div>
        </Modal.Header>
        <Modal.Body className='px-5 pb-4'>
            <Row>
                <Col xs={12}>
                    <div className='form-group'>
                        <label htmlFor="searchLists">
                            {t('modal_videosearch_label_videoinlists')}
                        </label>
                        <Select
                            value={selectedLists}
                            onChange={setSelectedLists}
                            defaultValue={currentLists}
                            placeholder={t('modal_videosearch_placeholder_videoinlists')}
                            options={availableLists} isMulti
                        ></Select>
                    </div>
                </Col>
                <Col xs={12}>
                    <div className="form-group">
                        <label htmlFor="searchTags">
                            {t('modal_videosearch_label_markerswithtags')}
                        </label>
                        <div className="dropdown border rounded px-2 pt-1">
                            <input value={searchTags} onChange={handleSearchTags}
                                title={t('modal_videosearch_placeholder_markerswithtags')}
                                type="text" className="without-border d-block w-100 px-1 mb-1"
                                id="searchTags" name='searchTags' data-toggle="dropdown"/>
                            <div className="dropdown-menu" style={{maxHeight:'180px', overflowY:'scroll', marginLeft:'320px'}}>
                                { availableTags.length == 0 && <div className="dropdown-menu-item px-3">
                                    <span className="text-muted">
                                        {t('modal_videosearch_message_nomatches')}
                                    </span>
                                </div> }
                                { availableTags.map(tag =>
                                <div key={tag.tagId} onClick={() => selectTag(tag)} className="dropdown-item px-2 cursor-pointer">
                                    <IconPill iconText={tag.icon} iconColor={tag.color} iconSize="sm"
                                        pillText={(tag.parentTagName ? tag.parentTagName + ' / ' : '')  + tag.name}/>
                                </div>)}
                            </div>
                            <div className="d-flex w-100 pb-2" style={{overflowY:'scroll'}}>
                                { selectedTags.length > 0 && 
                                    <span onClick={removeAll} title={t('modal_videosearch_cleanselection_titleattr')}
                                        className="text-muted my-auto cursor-pointer material-icons-round">remove_circle</span> }
                                { selectedTags.map(st =>
                                    <IconPill key={st.tagId} onCloseClick={() => removeTag(st)}
                                        iconText={st.icon} iconColor={st.color} iconSize="sm" 
                                        pillText={(st.parentTagName ? st.parentTagName + ' / ' : '')  + st.name}
                                        closable={true} className='mx-1'/> )}
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={12}>
                    <div className="form-group">
                        <label htmlFor="searchTitle">
                            {t('modal_videosearch_label_titlematch')}
                        </label>
                        <input value={titleSearch} onChange={handleTitleSearch}
                            title={t('modal_videosearch_placeholder_titlematch')}
                            type="text" className="form-control" id="searchTitle"/>
                    </div>
                </Col>
                <Col xs={12}>
                    <div className="form-group mt-3">
                        <div className='custom-control custom-switch' style={{zIndex:0}}>
                            <input checked={showOnlyDeleted}
                                onChange={evt => setShowOnlyDeleted(evt.target.checked)}
                                title={t('modal_videosearch_title_onlydeleted')}
                                type="checkbox" class="custom-control-input" id="showOnlyDeleted"/>
                            <label htmlFor="showOnlyDeleted" className='custom-control-label'>
                                {t('modal_videosearch_label_onlydeleted')}
                            </label>
                        </div>
                    </div>
                </Col>
                { false &&
                <Col xs={12}>
                    <div className="form-group">
                        <label htmlFor="searchUser">{t('modal_videosearch_label_postedby')}</label>
                        <input type="text" className="form-control" id="searchUser"/>
                    </div>
                </Col> }
            </Row>
        </Modal.Body>
    </Modal>)
}

export { ModalAvancedSearch };